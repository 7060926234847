import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const NotFoundPage: React.FunctionComponent = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  React.useEffect(() => {
    setIsInitialized(true);
  });
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "streamline-logo.webp" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`);

return (
  <section id="home" style={{height: '100%', width: '100%'}}>
    <div className="banner-text-wrapper">
      <h1 className="banner-text">
        <span className="text-orange"><FormattedMessage id="404" defaultMessage="404" /></span> <br/>
        <span><FormattedMessage id="404.title" defaultMessage="Page not found" /></span>
      </h1>
    </div>
    <div className="banner"></div>
    <GatsbyImage
      className="banner-logo"
      image={data.file.childImageSharp.gatsbyImageData}
      alt="streamline-logo" />
  </section>
  )
};

export default NotFoundPage;
